import React from "react";
import '../../App.css';
import MainPage from "../MainPage";
import Cards from "../Cards";

function Home() {
    return (
        <>
            <MainPage/>
            <Cards />
        </>
    )
}

export default Home;